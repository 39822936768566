
$moduleNameSpace: dynamic-name-space;
$error: #dc1431;
$errorBg: #f9dee1;
$success: #7bb63f;
$successBg: #eef6e6;
$info: #007dc3;
$infoBg: #deeef7;
$warning: #b05705;
$warningBg: #fcf0e6;
$background: #fff;
$hoverState: #012169;
$chevronIconPath: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTguNzAxIDIwTDcuMjkgMTguNThsNi41ODctNi41ODVMNy4yOSA1LjQxMSA4LjcwMSA0bDguMDA5IDcuOTk1TDguNzAxIDIweiIgZmlsbD0iIzAwNTNjMiIvPjwvc3ZnPg==';
$chevronIconPathHover: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTguNzAxIDIwTDcuMjkgMTguNThsNi41ODctNi41ODVMNy4yOSA1LjQxMSA4LjcwMSA0bDguMDA5IDcuOTk1TDguNzAxIDIweiIgZmlsbD0iIzAxMjE2OSIvPjwvc3ZnPg==';
[data-sparta-container] {
  .spartaMessage {
    border: 1px solid #333;
    background-color: $background;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    p {
      font-size: 14px;
      margin: 0;
      padding: 0 0 10px 0;
      &:last-child {
        padding-bottom: 0;
      }
    }
    .spartaMessage-content {
      flex: 1;
      float: left;
      padding-top: 28px;
      padding-bottom: 28px;
    }
    .spartaMessage-icon {
      flex: 0 0 80px;
      padding: 20px 0;
      float: left;
      margin-right: 20px;
    }
    .spartaMessage-dismiss {
      flex: 0 0 25px;
    }
    .spartaMessage-title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 10px;
      margin-top: 0;
    }
    .spartaMessage-list {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      margin-left: 13px;
    }
    .spartaMessage-close-x {
      cursor: pointer;
      position: relative;
      color: #A39382;
      font-size: 40px;
      float: right;
      margin-top: 10px;
      margin-right: 10px;
      &:focus,
      &:hover {
        color: #524940;
        text-decoration: none;
        outline: 1px dotted #524940;
      }
    }
    .spartaMessage-icon {
      position: relative;
      .data-icon {
        margin: 0 auto;
        display: block;
        width: 40px;
        height: 40px;
        position: relative;
        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
        }
      }
      .ada-text-message-icon {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        top: 0;
      }
    }
    &.error {
      border-color: $error;
      .spartaMessage-icon {
        background: $errorBg;
      }
      .spartaMessage-title {
        color: $error;
      }
    }
    &.success {
      border-color: $success;
      .spartaMessage-icon {
        background: $successBg;
      }
    }
    &.info {
      border-color: $info;
      .spartaMessage-icon {
        background: $infoBg;
      }
    }
    &.warning {
      border-color: $warning;
      .spartaMessage-icon {
        background: $warningBg;
      }
    }
    &.large {
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 40px;
      p {
        font-size: 14px;
        padding: 0 0 10px 0;
        &:last-child {
          padding-bottom: 0;
        }
      }
      .spartaMessage-content {
        padding-top: 28px;
        padding-bottom: 28px;
      }
      .spartaMessage-icon {
        flex: 0 0 80px;
        padding: 20px 0;
        margin-right: 20px;
      }
      .spartaMessage-dismiss {
        flex: 0 0 25px;
      }
      .spartaMessage-title {
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .spartaMessage-close-x {
        font-size: 40px;
        margin-top: 10px;
        margin-right: 10px;
      }
      .spartaMessage-icon {
        .data-icon {
          width: 40px;
          height: 40px;
          background-size: 40px 40px;
        }
      }
    }
    &.medium {
      font-size: 14px;
      line-height: 16px;
      margin-top: 15px;
      margin-bottom: 15px;
      p {
        font-size: 14px;
        line-height: 16px;
        padding: 0 0 10px 0;
        &:last-child {
          padding-bottom: 0;
        }
      }
      .spartaMessage-content {
        padding-top: 22px;
        padding-bottom: 22px;
      }
      .spartaMessage-icon {
        flex: 0 0 60px;
        padding: 16px 0;
        margin-right: 20px;
      }
      .spartaMessage-dismiss {
        flex: 0 0 25px;
      }
      .spartaMessage-title {
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .spartaMessage-close-x {
        font-size: 30px;
        margin-top: 5px;
        margin-right: 5px;
      }
      .spartaMessage-icon {
        .data-icon {
          width: 28px;
          height: 28px;
          background-size: 28px 28px;
        }
      }
    }
    &.small {
      font-size: 11px;
      line-height: 14px;
      margin-top: 10px;
      margin-bottom: 10px;
      p {
        font-size: 11px;
        line-height: 14px;
        padding: 0 0 6px 0;
        &:last-child {
          padding-bottom: 0;
        }
      }
      .spartaMessage-content {
        padding-top: 7px;
        padding-bottom: 7px;
      }
      .spartaMessage-icon {
        flex: 0 0 40px;
        padding: 6px 0;
        margin-right: 12px;
      }
      .spartaMessage-dismiss {
        flex: 0 0 25px;
      }
      .spartaMessage-title {
        font-size: 11px;
        font-weight: bold;
        padding-bottom: 6px;
      }
      .spartaMessage-close-x {
        font-size: 20px;
        margin-top: 3px;
        margin-right: 3px;
      }
      .spartaMessage-icon {
        .data-icon {
          width: 18px;
          height: 18px;
          background-size: 18px 18px;
        }
      }
    }
  }


  form .spartaMessage.error {
    &:focus-within {
      outline: 3px solid $error;
      outline-offset: 2px;
    }

      .spartaMessage-content button.spa-input-error-utility-button-show-hide {
      position: relative;
      top: 5px;
      margin-top: 5px;
      padding: 10px 4px;
      margin-bottom: 4px;
      text-decoration: none;

        &:hover {
        outline: 1px solid $hoverState;
        border-radius: 2px;
        text-decoration: none;

        .spa-icon {
          background-image: url($chevronIconPathHover);
        }
      }

      .spa-icon {
        position: relative;
        display: inline-block;
        top: -2px;
        left: -5px;
        margin-right: -4px;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        background-image: url($chevronIconPath);
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: center;

        &[class*="icon-chevron-up"] {
          transform: rotate(-90deg);
        }

          &:hover {
          border-color: transparent;
          text-decoration: none;
        }
      }
    }
  }
}
